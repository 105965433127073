import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../../context/authContext";
import { useCompany } from "../../../context/companyContext";
import api from "../../../services/api";
import handleError from '../../../helpers/errorHandler'

import { DeleteBox } from '../../deleteBox'
import TablePaginationActions, { theme } from '../../../helpers/tableHelpers'

import { ThemeProvider, Button, ButtonGroup } from "@mui/material";
import { Table, TableHead, TableCell, TableFooter, TableBody,
  TablePagination, TableRow } from "@mui/material" 

import { AiOutlineEdit, AiOutlineDelete, AiOutlinePlusCircle } from 'react-icons/ai' 

import "./styles.css";

export default function CompaniesList() {
  const [isActionBoxOpen, setIsActionBoxOpen] = useState(false);
  const [companyToDeleteId, setCompanyToDeleteId] = useState(null);

  const [companies, setCompanies] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(4);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : rowsPerPage-companies.length;

  const { generateToken } = useAuth();
  const { setCompany } = useCompany();
  const history = useHistory();

  useEffect(() => {
    async function loadCompanies() {
      try {
        const config = generateToken();
        const response = await api.get("/companies", config);
        setCompanies(response.data.subMenus);
      } catch (err) {
          const error = handleError(err)
          alert(error[0])
          if (error.length === 2) console.log(error[1])
      }
    }
    loadCompanies();
  }, [generateToken]);


  const deleteCompany = async (isDeleteConfirmed) =>{
    if (!isDeleteConfirmed) {
      setIsActionBoxOpen(false); 
      return
    }

    try {
      const config = generateToken();

      await api.delete(`/companies/${companyToDeleteId}`, config);

      const filteredCompanies = companies.filter(
        (company) => company.id !== companyToDeleteId
      );
      setIsActionBoxOpen(false);
      setCompanies(filteredCompanies);
      setCompany({})
    } catch (err) {
        const error = handleError(err)
        alert(error[0])
        if (error.length === 2) console.log(error[1])
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Table className="companiesList" aria-label="Customização">
        <TableHead>
          <TableRow>
          <TableCell              
                className="table-header"
                style={{textAlign: 'start'}} 
                >
                <span style={{paddingLeft: '2rem'}} >
                  Listagem de Empresas
                </span>
              </TableCell>
            <TableCell className="hide"/>
            <TableCell className="hide"/>

            <TableCell className="table-header" align="center">
              <Button
                className="button-add"
                onClick={() => { history.push("/companies/companies-list/add")}}
              >
                <span className="hide" style={{marginRight: '0.5rem'}}>Adicionar</span>
                <AiOutlinePlusCircle/>
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell className="table-head">Nome da Empresa</TableCell>
            <TableCell className={`table-head hide`}>
              CNPJ
            </TableCell>
            <TableCell className={`table-head hide`} >
              Logo
            </TableCell>
            <TableCell className="table-head" >
              Opções
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {companies
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((company) => (
            <TableRow key={company.id} style={{height: '5rem'}}>
              <TableCell
                className="table-cell"
              >
                {company.name}
              </TableCell>
              <TableCell
                className={`table-cell hide`}
              >
                {company.cnpj}
              </TableCell>
              <TableCell
                className={`table-cell hide`}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={{ borderRadius: "1rem", height: "36px" }}
                    src={company.url}
                    alt="logo"
                  />
                </div>
              </TableCell>
              <TableCell
                className="table-cell"
                align="center"
              >
                <ButtonGroup style={{position: 'relative'}} aria-label="outlined button group">
                  <Button
                    className="button-edit"
                    onClick={() => {
                      setCompany(company)
                      history.push(`companies-list/edit/${company.id}`);
                    }}
                  >
                    <span className="hide" style={{marginRight: '0.5rem'}}>Editar</span>
                    <AiOutlineEdit/>
                  </Button>
                  
                  <Button
                    className="button-remove"
                    onClick={() => {setIsActionBoxOpen(true); setCompanyToDeleteId(company.id)}}
                  >
                    <span className="hide" style={{marginRight: '0.5rem'}}>Excluir</span>
                    <AiOutlineDelete/>
                  </Button>
                      
                  { companyToDeleteId === company.id &&<DeleteBox isOpen={isActionBoxOpen} action={deleteCompany}/>}

                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 70 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}

        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              className="pagination"
              rowsPerPageOptions={[]}
              count={companies.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, nP)=>{setPage(nP)}}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </ThemeProvider>
  );
}
