import { useRef, useState } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../../context/authContext";
import { useCompany } from "../../../context/companyContext";
import api from "../../../services/api";
import handleError from '../../../helpers/errorHandler'

import { DownloadField } from "../../downloadField";
import { InputField } from "../../inputField";

import "./styles.css";

export default function CompanyUserForm({ method="POST" }) {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { generateToken } = useAuth();
  const { thisCompany } = useCompany();
  const history = useHistory();
  const formRef = useRef()

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmiting(true);

    try{
      const htmlContent = [...formRef.current.elements]
      let companiesFormData = new FormData()

      for(let i=0; i<htmlContent.length; i++){
        let { name, value } = htmlContent[i]
        
        if (method === "PUT" && value === "") continue
        switch(name){
          case "": break;
          case "file": companiesFormData.append('file', htmlContent[i].files[0])
            break;
          default: companiesFormData.append(name, value)
            break;
        }
        
      }
      const config = generateToken();
      if (method === "POST") {
      await api.post("/companies", companiesFormData, config);
    }
      else if (method === "PUT") {
        await api.put(`/companies/${thisCompany.id}`, companiesFormData, config);
      }
      history.push("/companies/companies-list")

    } catch(err){
      const error = handleError(err)
      alert(error[0])
      if (error.length === 2) console.log(error[1])
    }finally{ setIsSubmiting(false); }
  };

  const cancelAction = (e) => {
    e.preventDefault();
    history.push("/companies/companies-list");
  };

  if (method === "POST") {
    return (
      <form ref={formRef} onSubmit={onSubmit} className="form-style" formEncType="multipart/form-data">
        <h1>Cadastrar Empresa</h1>
        <div className="inputs-container">
          <InputField
            className="customInput"
            name="name"
            label="Nome da Empresa"
            type="text"
            placeholder="Digite um Nome"
            required={true}
          />

          <InputField 
            className="customInput"
            name="cnpj"
            label="CNPJ" 
            type="text" 
            pattern="[0-9]{14}"
            placeholder="Digite o CNPJ" 
            required={true}
            message="O CNPJ deve conter 14 dígitos"
          />

          <DownloadField
            labelName="Logo da Empresa"
            mimeTypes={["image/jpeg","image/pjpeg","image/png"]}
            multipleFiles={true}
            required={true}
          />

        </div>
        <div className="buttons-wrapper">
          <button type="submit" className="form-button" disabled={isSubmiting}>
            Salvar
          </button>
          <button onClick={cancelAction} className="form-button">
            Cancelar
          </button>
        </div>
      </form>
    );

  } else if (method === "PUT") {
    return (
      <form ref={formRef} onSubmit={onSubmit} className="form-style" formEncType="multipart/form-data">
        <h1>Editar Empresa</h1>
        <div className="inputs-container">
          <InputField
            className="customInput"
            name="name"
            label="Nome da Empresa"
            type="text"
            placeholder={thisCompany?.name}
          />

          <InputField 
            className="customInput"
            name="cnpj"
            label="CNPJ" 
            type="text" 
            pattern="[0-9]{14}"
            placeholder={thisCompany?.cnpj}
            message="O CNPJ deve conter 14 dígitos"
            />

          <DownloadField
            labelName="Logo da Empresa"
            mimeTypes={["image/jpeg","image/pjpeg","image/png"]}
            multipleFiles={true}
          />
          
        </div>
        <div className="buttons-wrapper">
          <button type="submit" className="form-button"disabled={isSubmiting}>
            Salvar
          </button>
          <button onClick={cancelAction} className="form-button">
            Cancelar
          </button>
        </div>
      </form>
    );
  }
}
