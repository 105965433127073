import { useRef, useState } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../../context/authContext";
import { useCompany } from "../../../context/companyContext";
import api from "../../../services/api";
import handleError from '../../../helpers/errorHandler'

import { DownloadField } from "../../downloadField/index";
import { InputField } from "../../inputField";

import "./styles.css";

export default function CompanyPagesForm({ method="POST" }) {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { generateToken } = useAuth();
  const { subMenuToEdit, thisCompany, menuToEdit, menus, setMenus, setMenuToEdit } = useCompany();
  const history = useHistory();
  const formRef = useRef()


const onSubmit = async (e) => {
  e.preventDefault();
  setIsSubmiting(true);

  try {
    const htmlContent = [...formRef.current.elements]
    let companiesFormData = new FormData()
    for (let i = 0; i < htmlContent.length; i++) {
        let { name, value, files } = htmlContent[i]
        if (method === "PUT" && value === "") continue
        switch (name) {
          case "": continue
          case "file": companiesFormData.append('file', files[0])
              break;
          default: companiesFormData.append(name, value)
        }
    }

    companiesFormData.append('company_id', thisCompany.id)
    companiesFormData.append('link', menuToEdit.id)

    const config = generateToken();
    if (method === "POST") {
      const response = await api.post("/admin/sublink", companiesFormData, config);
      const newSubMenu = response.data.sublink.sublink
      let newMenu = {}
    
      const updatedMenus = menus.map((menu)=>{
        if (menu.id === menuToEdit.id){
          newMenu = { ...menu, sublink:[...menu.sublink, newSubMenu] }
          return newMenu
        }
        return menu
      })
      
      setMenuToEdit(newMenu)
      setMenus(updatedMenus)
    
      } else if (method === "PUT") {
        const response = await api.put(`/admin/sublink/${subMenuToEdit.id}`, companiesFormData, config);
        const editedSubMenu = response.data.sublink.sublink 
        const newSubMenu = { ...subMenuToEdit, ...editedSubMenu}
        let newMenu = {}

        const updatedMenus = menus.map((menu)=>{
          if (menu.id === menuToEdit.id) {
            newMenu = { ...menu }

            newMenu.sublink = newMenu.sublink.map((submenu)=>{
              if (submenu.id === newSubMenu.id){ 
              return newSubMenu
              }
              return submenu
            })
            return newMenu
          }
          return menu
        })
        setMenuToEdit(newMenu)
        setMenus(updatedMenus)
      }
    history.push(`/company/${thisCompany.id}/submenus-list`)

  } catch (err) {
      const error = handleError(err)
      alert(error[0])
      if (error.length === 2) console.log(error[1])
  } finally{ setIsSubmiting(false); }
};

const cancelAction = (e) => {
    e.preventDefault();
    history.push(`/company/${thisCompany.id}/submenus-list`);
};

if (method === "POST") {
  return (
    <form ref={formRef} onSubmit={onSubmit} className="formPage-style" formEncType="multipart/form-data">
      <h1>Cadastrar SubLink</h1>
      <div className="inputs-container">
        <InputField
          className="customInput"
          name="subname"
          label="Nome da SubLink"
          type="text"
          placeholder="Digite um Nome"
          required={true}
        />

        <DownloadField
          labelName="Página HTML"
          mimeTypes="text/html"
          multipleFiles={false}
          required={true}
        />
      </div>
      <div className="buttons-wrapper">
        <button type="submit" className="form-button" disabled={isSubmiting}>
          Salvar
        </button>
        <button onClick={cancelAction} className="form-button">
          Cancelar
        </button>
      </div>
    </form>
  );

} else if (method === "PUT") {
    return (
      <form ref={formRef} onSubmit={onSubmit} className="form-style" formEncType="multipart/form-data">
        <h1>Editar SubLink</h1>
        <div className="inputs-container">
          <InputField
            className="customInput"
            name="subname"
            label="Nome do Sublink"
            type="text"
            placeholder={subMenuToEdit.name}
          />

          <DownloadField
            labelName="Página HTML"
            mimeTypes="text/html"
            multipleFiles={true}
          />

        </div>
        <div className="buttons-wrapper">
          <button type="submit" className="form-button" disabled={isSubmiting}>
            Salvar
          </button>
          <button onClick={cancelAction} className="form-button">
            Cancelar
          </button>
        </div>
      </form>
    );
  }
}