import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import { useAuth } from "../../../context/authContext";
import { useCompany } from "../../../context/companyContext";
import api from "../../../services/api";
import handleError from "../../../helpers/errorHandler";

import logo from "../../../assets/logo.png";

import { Menu, Button } from "antd";
import {
  AiOutlineMenu,
  AiOutlineUser,
  AiOutlinePoweroff,
  AiOutlineUsergroupAdd,
  AiOutlineSolution,
} from "react-icons/ai";

import "antd/dist/antd.css";
import "./styles.css";

export default function CompaniesNavBar() {
  const [collapsed, setCollapsed] = useState(false);
  const [subMenus, setSubMenus] = useState([]);

  const { data, generateToken, signOut } = useAuth();
  const { thisCompany, setCompany, setMenus } = useCompany();
  const { id, name, user_type } = data.user;

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    async function loadCompanies() {
      if (pathname.split("/")[1] !== "companies") return;
      try {
        const config = generateToken();
        const response = await api.get("/companies", config);
        setSubMenus(response.data.subMenus);
      } catch (err) {
        const error = handleError(err);
        alert(error[0]);
        if (error.length === 2) console.log(error[1]);
      }
    }
    loadCompanies();
  }, [generateToken, pathname, thisCompany]);

  const handleClick = async (current) => {
    const companyId = current.key;
    try {
      const config = generateToken();
      const response = await api.get(`/company/${companyId}`, config);

      setCompany(response.data.company);
      setMenus(response.data.subMenu);

      history.push(`/company/${companyId}`);
      window.location.reload(false);
    } catch (err) {
      alert(err.response.data.message);
    }
  };

  const toggleCollapsed = () => {
    let navbar = document.querySelector(".navbar");
    navbar.classList.toggle("open");
    setCollapsed(!collapsed);
  };

  const SubMenusToRender = subMenus.map((subMenuRender) => {
    return (
      <Menu.Item className="menuItemCompanies" key={subMenuRender.id}>
        {subMenuRender.name}
      </Menu.Item>
    );
  });

  return (
    <div className="navbar">
      <div className="container">
        <div className="headerMenu">
          <div className="Button">
            <Button id="navbar-button" onClick={toggleCollapsed}>
              <AiOutlineMenu />
            </Button>
          </div>
          <div className="logos">
            <ul>
              <li>
                <img id="aqualogicLogo" src={logo} alt="Logo Aqualogic" />
              </li>
            </ul>
          </div>
          <div
            className="members"
            onClick={() => {
              history.push(`/companies/users/edit/${id}`);
            }}
          >
            <AiOutlineUser id="members" />
            <p className="companies-user">
              {name} - {user_type}
            </p>
          </div>
        </div>
        <Menu
          inlineCollapsed={collapsed}
          className="menuCompanies"
          onClick={handleClick}
          mode="inline"
        >
          {SubMenusToRender}
        </Menu>

        <Button
          className="editButton"
          style={{transition: '5s'}}
          onClick={() => {
            history.push("/companies/companies-list");
          }}
        >
          <AiOutlineSolution className="iconButton" />
          <div id="logout-text">Empresas</div>
        </Button>
        <Button
          className="editButton"
          onClick={() => {
            history.push("/companies/users-list");
          }}
        >
          <AiOutlineUsergroupAdd className="iconButton" />
          <div id="logout-text">Usuários</div>
        </Button>
        <Button
          className="logout"
          onClick={() => {
            signOut();
            history.push("/login");
            window.location.reload(false);
          }}
        >
          <AiOutlinePoweroff className="iconButton" />
          <div id="logout-text">Logout</div>
        </Button>
      </div>
    </div>
  );
}
