import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { useCompany } from "../context/companyContext";

import Box from "@mui/material/Box";

import CompanyNavBar from "../components/company/companyNavbar";
import HeaderComponent from "../components/header";
import CompanyUserList from "../components/company/companyUserList";
import CompanyUserForm from "../components/company/companyUserForm";
import CompaniesUserForm from "../components/companies/companiesUserForm";
import CompanyMenusList from "../components/company/companyMenusList";
import CompanyMenusForm from "../components/company/companyMenusForm";
import CompanySubMenusList from "../components/company/companySubMenusList";
import CompanySubMenuForm from "../components/company/companySubMenusForm";
import RPage from '../components/rPage'

export default function Company() {
  const { thisCompany } = useCompany();
  const { data } = useAuth();
  const { user_type } = data.user
  
  return (
    <Box sx={{ display: "flex" }}>
      <BrowserRouter>
        <CompanyNavBar/>
        <Switch>
          <Route path="/company/:id" exact>
          <div className="content-body">
              <HeaderComponent title={thisCompany.name} />
            </div>
          </Route>

          <Route path="/company/:id/page/:id" exact>
            <div className="content-body">
              <RPage/>
            </div>
          </Route>
          
          <Route path="/company/:id/menus-list" exact>
            {user_type !== "master" ? (
              <Redirect to="/company" />
            ) : (
              <div className="content-body">
                <HeaderComponent title={thisCompany.name} />
                <div className="content">
                <CompanyMenusList />
              </div>
              </div>
            )}
          </Route>

          <Route path="/company/:id/menus-list/edit" exact>
            {user_type !== "master" ? (
              <Redirect to="/company" />
            ) : (
              <div className="content-body">
                <HeaderComponent title={thisCompany.name} />
                <div className="content">
                <CompanyMenusForm method="PUT"/>
              </div>
              </div>
            )}
          </Route>

          <Route path="/company/:id/menus-list/add" exact>
            {user_type !== "master" ? (
              <Redirect to="/company" />
            ) : (
              <div className="content-body">
                <HeaderComponent title={thisCompany.name} />
                <div className="content">
                <CompanyMenusForm method="POST"/>
              </div>
              </div>
            )}
          </Route>

          <Route path="/company/:id/submenus-list" exact>
            {user_type !== "master" ? (
              <Redirect to="/company" />
            ) : (
              <div className="content-body">
                <HeaderComponent title={thisCompany.name} />
                <div className="content">
                <CompanySubMenusList />
              </div>
              </div>
            )}
          </Route>

          <Route path="/company/:id/submenus-list/edit" exact>
            {user_type !== "master" ? (
              <Redirect to="/company" />
            ) : (
              <div className="content-body">
                <HeaderComponent title={thisCompany.name} />
                <div className="content">
                <CompanySubMenuForm method="PUT"/>
              </div>
              </div>
            )}
          </Route>

          <Route path="/company/:id/submenus-list/add" exact>
            {user_type !== "master" ? (
              <Redirect to="/company" />
            ) : (
              <div className="content-body">
               <HeaderComponent title={thisCompany.name} />
                <div className="content">
                <CompanySubMenuForm method="POST"/>
              </div>
              </div>
            )}
          </Route>

          <Route path="/company/:id/users-list" exact>
            {user_type === "user" ? (
              <Redirect to="/company" />
            ) : (
              <div className="content-body">
                <HeaderComponent title={thisCompany.name} />
                <div className="content">
                <CompanyUserList />
              </div>
              </div>
            )}
          </Route>

          <Route path="/company/:id/users-list/edit/:id" exact>
            {user_type === "user" ? (
              <Redirect to="/company" />
            ) : (
              <div className="content-body">
                <HeaderComponent title={thisCompany.name} />
                <div className="content">
                <CompanyUserForm title="Edição de usuário da empresa" method="PUT"/>
              </div>
              </div>
            )}
          </Route>

          <Route path="/company/:id/users-list/add" exact>
            {user_type === "user" ? (
              <Redirect to="/company" />
            ) : (
              <div className="content-body">
                <HeaderComponent title={thisCompany.name} />
                <div className="content">
                <CompanyUserForm title="Cadastro de usuários da empresa" method="POST"/>
              </div>
              </div>
            )}
          </Route>

          <Route path="/company/:id/user/edit/:id" exact>
            <div className="content-body">
              <HeaderComponent title={thisCompany.name} />
              <div className="content">
              { user_type === "master"
               ? <CompaniesUserForm title="Edição do seu usuário" method="PUT"/>
               : <CompanyUserForm title="Edição do seu usuário" method="PUT"/>
              }
              </div>
            </div>
          </Route>


          <Route path="/companies">
            <div className="content-body">
              <HeaderComponent title={"CARREGANDO"} />
            </div>
          </Route>

          <Route path="/">
            <div className="content-body">
              <HeaderComponent title={"Parece que você se perdeu"} />
              <Redirect to={`/company/${thisCompany.id}`}/>
            </div>
          </Route>
        </Switch>
      </BrowserRouter>
    </Box>
  );
}
