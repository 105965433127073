import React, { useState } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../../context/authContext";
import { useCompany } from "../../../context/companyContext";
import api from "../../../services/api";
import handleError from '../../../helpers/errorHandler'

import { DeleteBox } from '../../deleteBox'
import TablePaginationActions, { theme } from '../../../helpers/tableHelpers'

import { ThemeProvider, Button, ButtonGroup } from "@mui/material";
import { Table, TableHead, TableCell, TableFooter, TableBody,
  TablePagination, TableRow } from "@mui/material" 

import { AiOutlineEdit, AiOutlineDelete, AiOutlinePlusCircle, AiOutlineSearch } from 'react-icons/ai' 

import "./styles.css";

export default function CompanySubMenusList() {
  const [isActionBoxOpen, setIsActionBoxOpen] = useState(false);
  const [submenuToDeleteId, setSubmenuToDeleteId] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(4);

  const { generateToken } = useAuth();
  const { menus, setMenuToEdit, menuToEdit, thisCompany, setSubMenuToEdit, setMenus } = useCompany();
  const history = useHistory();
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - menuToEdit.length) : rowsPerPage-menuToEdit.sublink.length;

  const deleteSubMenu = async (isDeleteConfirmed) => {
    if (!isDeleteConfirmed) {
      setIsActionBoxOpen(false); 
      return
    }

    try {
      const config = generateToken();

      await api.delete(`/admin/sublink/${submenuToDeleteId}`, config);

      const filteredSubLinks = menuToEdit.sublink.filter(
        (sublink) => sublink.id !== submenuToDeleteId
      );
      
      const editedMenus = menus.map((menu)=>{
        if (menu.id === menuToEdit.id) return { ...menuToEdit, sublink:filteredSubLinks }
        return menu
      })
      setIsActionBoxOpen(false); 
      setMenus(editedMenus)
      setMenuToEdit({ ...menuToEdit, sublink:filteredSubLinks })
    } catch (err) {
        const error = handleError(err)
        alert(error[0])
        if (error.length === 2) console.log(error[1])
    }
  };

  return (
    <ThemeProvider theme={theme}>
        <Table className="companiesSubMenusList" aria-label="Customização">
          <TableHead>
            <TableRow >
              <TableCell   colSpan={2}                
                className="table-header"
                style={{textAlign: 'start'}} 
                >
                <span style={{paddingLeft: '2rem'}} >
                Listagem de Sublinks da Empresa
                </span>
              </TableCell>
              <TableCell className="table-header" style={{textAlign: 'end'}}>
                <span style={{paddingRight: '2rem'}}>
                  <Button
                      className="button-add"
                      onClick={() => {
                        history.push(`/company/${thisCompany.id}/submenus-list/add`);
                      }}
                    >
                    <span className="hide" style={{marginRight: '0.5rem'}}>Adicionar</span>
                    <AiOutlinePlusCircle/>
                  </Button>
                </span>
                
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow  >
              <TableCell colSpan={2} className="table-head">Nome</TableCell>
              
              <TableCell colSpan={2} className="table-head" >
                Opções
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {menuToEdit.sublink
              .slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage)
              .map((subMenu) => (
                <TableRow key={subMenu.id} style={{height: '70px'}}>
                <TableCell  colSpan={2}
                  className="table-cell"
                >
                  {subMenu.name}
                </TableCell>
                              
                <TableCell
                  colSpan={2}
                  className="table-cell"
                  align="center"
                >
                  <ButtonGroup style={{position: 'relative'}} aria-label="outlined button group">
                    <Button
                      className="button-more"
                      style={{width: '10rem'}}
                      onClick={() => {window.open(subMenu.rpage_url,'_blank')}}
                    >
                      <span className="hide" style={{marginRight: '0.5rem'}}>Ver Página</span>
                      <AiOutlineSearch/>
                    </Button>
                    <Button
                      className="button-edit"
                      onClick={() => {
                        setSubMenuToEdit(subMenu)
                        history.push(
                          `/company/${thisCompany.id}/submenus-list/edit`
                        );
                      }}
                    >
                      <span className="hide" style={{marginRight: '0.5rem'}}>Editar</span>
                      <AiOutlineEdit/>
                    </Button>
                    <Button
                      className="button-remove"
                      onClick={() => {setIsActionBoxOpen(true); setSubmenuToDeleteId(subMenu.id)}}
                    >
                      <span className="hide" style={{marginRight: '0.5rem'}}>Excluir</span>
                      <AiOutlineDelete/>
                    </Button>

                    { submenuToDeleteId === subMenu.id && <DeleteBox isOpen={isActionBoxOpen} action={deleteSubMenu} style={{left: '13.4rem'}}/> }

                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 70 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
            
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                className="pagination"
                rowsPerPageOptions={[]}
                count={menuToEdit.sublink.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(_, nP)=>{setPage(nP)}}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
    </ThemeProvider>
  );
}
