import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Box from "@mui/material/Box";

import CompaniesNavBar from "../components/companies/companiesNavbar";
import HeaderComponent from "../components/header";
import CompaniesUserList from "../components/companies/companiesUserList";
import CompaniesUserForm from "../components/companies/companiesUserForm";
import CompaniesList from "../components/companies/companiesList";
import CompaniesForm from "../components/companies/companiesForm";

export default function Companies() {
  return (
    <Box sx={{ display: "flex" }}>
      <BrowserRouter>
        <CompaniesNavBar />
        <Switch>
          <Route path="/companies" exact>
            <div className="content-body">
              <HeaderComponent title={"EMPRESAS"} />
            </div>
          </Route>
          <Route path="/companies/companies-list" exact>
            <div className="content-body">
              <HeaderComponent title={"ACQUA LOGIC"} />
              <div className="content">
                <CompaniesList />
              </div>
            </div>
          </Route>
          <Route path="/companies/companies-list/edit/:id">
            <div className="content-body">
              <HeaderComponent title={"ACQUA LOGIC"} />
              <div className="content">
                <CompaniesForm method="PUT" />
              </div>
            </div>
          </Route>

          <Route path="/companies/companies-list/add">
            <div className="content-body">
              <HeaderComponent title={"ACQUA LOGIC"} />
              <div className="content">
                <CompaniesForm method="POST" />
              </div>
            </div>
          </Route>

          <Route path="/companies/users-list" exact>
            <div className="content-body">
              <HeaderComponent title={"ACQUA LOGIC"} />
              <div className="content">
                <CompaniesUserList />
              </div>
            </div>
          </Route>

          <Route path="/companies/users-list/edit/:id" exact>
            <div className="content-body">
              <HeaderComponent title={"ACQUA LOGIC"} />
              <div className="content">
                <CompaniesUserForm
                  title="Edição de usuários master"
                  method="PUT"
                />
              </div>
            </div>
          </Route>

          <Route path="/companies/users-list/add" exact>
            <div className="content-body">
              <HeaderComponent title={"ACQUA LOGIC"} />
              <div className="content">
                <CompaniesUserForm
                  title="Cadastro de usuários master"
                  method="POST"
                />
              </div>
            </div>
          </Route>

          <Route path="/companies/users/edit/:id" exact>
            <div className="content-body">
              <HeaderComponent title={"ACQUA LOGIC"} />
              <div className="content">
                <CompaniesUserForm title="Edição do seu usuário" method="PUT" />
              </div>
            </div>
          </Route>

          <Route path="/company/:id">
            <HeaderComponent title={"CARREGANDO"} />
          </Route>

          <Route path="/">
            <div className="content-body">
              <HeaderComponent title={"Parece que você se perdeu"} />
              <Redirect to="/companies" />
            </div>
          </Route>
        </Switch>
      </BrowserRouter>
    </Box>
  );
}
