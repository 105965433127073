import React, { useState } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../../context/authContext";
import { useCompany } from "../../../context/companyContext";

import logo from "../../../assets/logo.png";

import { IconPickerItem } from "react-fa-icon-picker";
import {
  AiOutlineMenu,
  AiOutlineUser,
  AiOutlinePoweroff,
  AiOutlineUsergroupAdd,
  AiTwotoneFund,
  AiOutlineRollback,
} from "react-icons/ai";

import { Menu, Button } from "antd";

import "antd/dist/antd.css";
import "./styles.css";

const { SubMenu } = Menu;

export default function CompanyNavBar() {
  const [collapsed, setCollapsed] = useState(false);

  const { data, signOut } = useAuth();
  const { thisCompany, setPageUrl, menus } = useCompany();
  const { id, name, company_id, user_type } = data.user;

  const history = useHistory();

  let SubMenusToRender = []

  const handleClick = (props) => {
    const rPageId = props.key.split('/').slice(-1)[0]
    if (collapsed === false){
      toggleCollapsed()
    }
    setPageUrl(props.key)
    history.push(`/company/${thisCompany.id}/page/${rPageId}`)
  };

  const toggleCollapsed = () => {
    let navbar = document.querySelector(".companyNavbar");
    navbar.classList.toggle("open");
    setCollapsed(!collapsed);
  };

  if (menus){
    SubMenusToRender = menus.map((subMenuRender) => {
      if (subMenuRender.simple) {
        return (
          <Menu.Item
            className="menuItemCompany"
            icon={
              <IconPickerItem icon={!!(subMenuRender.icon)?subMenuRender.icon:"FaAddressBook"} color="white" size={26} />
            }
            key={subMenuRender.rpage_url}
          >
            {subMenuRender.name}
          </Menu.Item>
        );
      } else {
        return (
          <SubMenu
            className="subMenu"
            title={subMenuRender.name}
            key={subMenuRender.id}
            icon={
              <IconPickerItem icon={!!(subMenuRender.icon)?subMenuRender.icon:"FaAddressBook"} color="white" size={26} />
            }
          >
            {subMenuRender.sublink.map((menuItem) => {
              return (
                <Menu.Item 
                  className="menuItem" 
                  key={menuItem.rpage_url} 
                >
                  {menuItem.name}
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      }
    });
  }

  return (
    <div className="companyNavbar">
      <div className="container">
        <div className="headerMenu">
          <div className="Button">
            <Button id="companyNavbar-button" onClick={toggleCollapsed}>
              <AiOutlineMenu />
            </Button>
          </div>
          <div className="logos">
            <ul>
              <li>
                <img id="aqualogicLogo" src={logo} alt="Logo Aqualogic" />
              </li>
              
            </ul>
          </div>

          <div className="members" onClick={()=>{
            history.push(`/company/${company_id}/user/edit/${id}`);
          }}>
            <AiOutlineUser id="members" />
            <p className="company-user">{name} - {user_type}</p>
          </div>
        </div>

        <img 
          id="companyLogo"
          src={thisCompany.url} 
          alt="Logo Empresa" 
          style={{marginBottom: '1rem', borderRadius: "1rem"}}
        />
        <Menu
          inlineCollapsed={collapsed}
          className="menu"
          onClick={handleClick}
          mode="inline"
        >
          {SubMenusToRender}
        </Menu>
        
        <div style={{
          display: 'flex', flexDirection: collapsed ? 'column':'row',
          width: collapsed ? '3.4rem': '80%',
          marginTop: '0.6rem'
        }}>

        {user_type !== "user" && (
          <Button 
            className="editButton" 
            style={{width: collapsed ? '3.4rem' : '' }}
            onClick={()=>{history.push(`/company/${thisCompany.id}/users-list`)}}>
            <AiOutlineUsergroupAdd className="iconButton" />
            <div id="logout-text">Usuários</div>
          </Button>
        )}

        {user_type === "master" && (
          <Button 
            className="editButton" 
            style={{marginLeft: collapsed? '0rem':'0.5rem', width: collapsed ? '3.4rem' : '' }} 
            onClick={()=>{history.push(`/company/${thisCompany.id}/menus-list`)}}
          >
            <AiTwotoneFund className="iconButton" />
            <div id="logout-text">Páginas</div>
          </Button>
        )}
        </div>

        {user_type === "master" && (
          <Button className="editButton" style={{width: collapsed ? '3.4rem' : '' }} onClick={()=>{history.push(`/companies`); window.location.reload(false)}}>
            <AiOutlineRollback className="iconButton" />
            <div id="logout-text">Companhias</div>
          </Button>
        )}

        <Button
          className="logout"
          style={{width: collapsed ? '3.4rem' : '' }}
          onClick={()=>{
            signOut()
            history.push('/login')
            window.location.reload(false)
          }}
        >
          <AiOutlinePoweroff className="iconButton" />
          <div id="logout-text">Logout</div>
        </Button>
      </div>
    </div>
  );
}
