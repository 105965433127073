import React from "react";
import ReactDOM from "react-dom";

import { AuthProvider } from './context/authContext'
import { CompanyProvider } from './context/companyContext'
import Routes from './routes'

import { config } from 'dotenv'

import "./index.css";

// Permitir o uso de variáveis ambiente
config()

function App() {
  return (
    <AuthProvider>
      <CompanyProvider>
        <Routes />
      </CompanyProvider>
    </AuthProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
