import React, { useState } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../context/authContext";
import { useCompany } from "../../context/companyContext";
import api from "../../services/api";

import * as yup from "yup";
import { ValidationError } from "yup";

import logo from "../../assets/logo.png";
import { Form, Input, Button } from "antd";
import { FormError } from "../../components/Error";

import "antd/dist/antd.css";
import "./styles.css";

export default function LoginComponent() {
  const history = useHistory();
  const { signIn, generateToken } = useAuth();
  const { setCompany, setMenus } = useCompany();

  const [error, setError] = useState("");

  async function onFinish(content) {
    try {
      const regex = /^(?=.*[@!#$%^&*()/\\])[@!#$%^&*()/\\a-zA-Z0-9]{6,20}$/;
      const schema = yup.object().shape({
        password: yup
          .string()
          .min(6, "A senha deve conter pelo menos 6 caractéres")
          .matches(regex, "A senha deve conter caractéres especiais"),
        // login: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
        login: yup.string().min(6, "O login deve ter pelo menos 6 caractéres"),
      });

      await schema.validate(content);
      const response = await api.post("/login", content);
      const { user, token } = response.data;

      signIn(user, token);

      if (user.user_type === "master") {
        history.push("/companies");
      } else {
        const config = generateToken(token);
        const response = await api.get(`/company/${user.company_id}`, config);

        setCompany(response.data.company);
        setMenus(response.data.subMenu);

        history.push(`/company/${user.company_id}`);
      }
    } catch (err) {
      if (err instanceof ValidationError) {
        setError(err.errors[0]);
      } else {
        setError("");
        alert("Credencias inválidas");
      }
    }
  }

  return (
    <div className="login">
      <div className="container">
        <div className="logoArea">
          <img id="logo" src={logo} alt="Logo Aqualogic" />
        </div>
        <div className="info">
          Bem-Vindo! Faça seu login para acessar o sistema.
        </div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="login"
            rules={[
              {
                required: true,
                message: "Por favor digite seu email ou número de celular!",
              },
            ]}
          >
            <Input placeholder="EMAIL / NÚMERO DE CELULAR" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Por favor digite sua senha!" }]}
          >
            <Input type="password" placeholder="SENHA" />
          </Form.Item>
          <div style={{ position: "relative" }}>
            <FormError message={error} />
            <Button type="primary" htmlType="submit">
              ENTRAR
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
