import { useState, useRef } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../../context/authContext";
import { useCompany } from "../../../context/companyContext";
import api from "../../../services/api";
import handleError from '../../../helpers/errorHandler'

import { IconPicker } from 'react-fa-icon-picker'

import { DownloadField } from "../../downloadField/index"
import { InputField } from "../../inputField";

import "./styles.css";

export default function CompanyPagesForm({ method="POST" }) {
  const [isSimple, setIsSimple] = useState(true)
  const [iconValue, setIconValue] = useState("");
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { generateToken } = useAuth();
  const { thisCompany, menuToEdit, menus, setMenus } = useCompany()
  const history = useHistory();
  const formRef = useRef()

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmiting(true);
    
    if (!iconValue && method === "POST"){
      alert("É obrigatório selecionar um ícone")
      return
    }

    try {
      const htmlContent = [...formRef.current.elements]
      let companiesFormData = new FormData()
      for (let i = 0; i < htmlContent.length; i++) {
        let { name, value, files } = htmlContent[i]
        if (method === "PUT" && value === "") continue

        switch (name) {
          case "": continue
          case "file": companiesFormData.append('file', files[0])
            break;
          case "simple":
            break;
          default: companiesFormData.append(name, value)
        }
        
      }
      companiesFormData.append('icon', iconValue ? iconValue : menuToEdit.icon)
      companiesFormData.append('company_id', thisCompany.id)
      companiesFormData.append('simple', isSimple)

      const config = generateToken();
      if (method === "POST"){ 
        const response = await api.post("/admin/link", companiesFormData, config);
        let newMenu = {}
        if (response.data?.link?.simple) {
          newMenu = response.data.link
        }else{
          newMenu = response.data.sublink.link
          newMenu = { ...newMenu, sublink:[response.data.sublink.sublink]}
        }
        setMenus([...menus, newMenu])
      
      }else if (method === "PUT") {
        const response = await api.put(`/admin/link/${menuToEdit.id}`, companiesFormData, config);
        const newMenu = response.data.link 
        const editedMenu = { ...menuToEdit, ...newMenu}

        const updatedMenus = menus.map((menu)=>{
          if (menu.id === editedMenu.id) return editedMenu
          return menu
        })
        setMenus(updatedMenus)
      }
      
      history.push(`/company/${thisCompany.id}/menus-list`)

    } catch (err) {
        const error = handleError(err)
        alert(error[0])
        if (error.length === 2) console.log(error[1])
    } finally{ setIsSubmiting(false); }
  }
  
  const cancelAction = (e) => {
    e.preventDefault();
    history.push(`/company/${thisCompany.id}/menus-list`);
  };

  if (method === "POST") {
    return (
      <form ref={formRef} onSubmit={onSubmit} className="formMenu-style" >
        <h1>Cadastrar Link</h1>

        <div className="radios-container">
          <div className="radio">
            <label htmlFor="menu" >Simples</label>
            <input checked={isSimple} type="radio" name="simple" id="menu" value="single" onChange={(e)=>{setIsSimple(true)}} />
          </div>

          <div className="radio">
            <label htmlFor="page">Múltiplo</label>
            <input checked={!isSimple} type="radio" name="simple" id="page" value="multiple" onChange={(e)=>{setIsSimple(false)}} />
          </div>
      </div>

        <div className="inputs-container">
          <div className="inputContainer" style={{display: "flex", alignItems: "center"}}>

          <InputField
            className="customInput"
            name="name"
            label="Nome do Link"
            type="text"
            placeholder="Digite o nome"
            required={true}
          />
      
            <div className="pickerContainer">
              <label htmlFor="picker">Ícone</label>
              <IconPicker 
                buttonIconStyles={{ color: "white" }}
                id="picker" 
                value={iconValue} 
                onChange={(v) => { setIconValue(v) }}
              />
            </div>
          </div>
          
          { !isSimple &&
            <InputField
              className="customInput"
              name="subname"
              label="Nome do primeiro sublink"
              type="text"
              placeholder="Digite o Nome"
              required={true}
            />
          }

          <DownloadField
            labelName={`Página HTML associada ao ${isSimple ? "link" : "sublink"}`}
            mimeTypes="text/html"
            multipleFiles={false}
            required={true}
          />
        </div>
        <div className="buttons-wrapper">
          <button type="submit" className="form-button" disabled={isSubmiting}>
            Salvar
          </button>
          <button onClick={cancelAction} className="form-button">
            Cancelar
          </button>
        </div>
      </form>
    );
  }

  else if (method === "PUT") {
    return (
      <form ref={formRef} onSubmit={onSubmit} className="formMenu-style" >
        <h1>Editar Link</h1>

        <div className="inputs-container">
          <div className="inputContainer" style={{display: "flex", alignItems: "center"}}>

          <InputField
            className="customInput"
            name="name"
            label="Nome do Link"
            type="text"
            placeholder={menuToEdit.name}
          />
      
            <div className="pickerContainer">
              <label htmlFor="picker">Ícone</label>
              <IconPicker 
                buttonIconStyles={{ color: "white" }}
                id="picker" 
                value={iconValue === "" ? menuToEdit.icon : iconValue} 
                onChange={(v) => { setIconValue(v) }}
              />
            </div>
          </div>

          { menuToEdit.simple &&
            <DownloadField
              labelName={`Página HTML associada ao link`}
              mimeTypes="text/html"
              multipleFiles={false}
            />
          }
        </div>
        <div className="buttons-wrapper">
          <button type="submit" className="form-button" disabled={isSubmiting}>
            Salvar
          </button>
          <button onClick={cancelAction} className="form-button">
            Cancelar
          </button>
        </div>
      </form>
    );
  }
}