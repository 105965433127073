import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useAuth } from '../../../context/authContext'
import { useCompany } from '../../../context/companyContext'
import api from '../../../services/api'
import handleError from '../../../helpers/errorHandler'

import { InputField } from '../../inputField'

import './styles.css'

export default function CompanyUserForm({ method="POST", title }) {
  const [userToEdit, setUserToEdit] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);
  
  const { pathname } = useLocation();
  const history = useHistory()
  const formRef = useRef()
  const { data, signIn, generateToken } = useAuth()
  const { thisCompany } = useCompany()

  useEffect(()=>{
    const config = generateToken()

    const getUser = async () =>{
      try{
        const userId = pathname.split("/").slice(-1)[0];
        const response = await api.get(`/company/user/${thisCompany.id}/${userId}`, config)
        setUserToEdit(response.data)

      }catch (err){
        const error = handleError(err)
        alert(error[0])
        if (error.length === 2) console.log(error[1])
      }
    }

    if (method === "PUT") getUser()
  },[generateToken, method, thisCompany.id, pathname])

  const onSubmit = async(e) =>{
    e.preventDefault();
    setIsSubmiting(true);
    
    const userFormToSubmit = [...formRef.current.elements].reduce((total, {name, value, placeholder})=>{
      // This if is used to handle the field when puting user
      if (value === "" && placeholder !== "E-mail alternativo" && name !== "password") value = placeholder
      switch(name){
        case "": return { ...total }
        case 'user_type': return { ...total, [name]: value.toLowerCase() };
        case 'company_id': return { ...total, [name]: thisCompany?.id }
        default: return { ...total, [name]:value }
      }
    },{})
    
    try{
      const config = generateToken()

      let delay = 0
      if (method === "POST") await api.post("/company/user", userFormToSubmit, config);
      else if (method === "PUT") {
        const response = await api.put(`/company/user/${userToEdit?.id}`, userFormToSubmit, config);
        if (userToEdit.id === parseInt(data.user.id)) signIn(response.data.user, data.token); delay = 200
      }
      
      setTimeout(()=>{
        if (data.user.user_type === "user") history.push(`/company/${thisCompany?.id}`)
        else history.push(`/company/${thisCompany?.id}/users-list`)
      },delay)
    
    }catch(err){
      const error = handleError(err)
      alert(error[0])
      if (error.length === 2) console.log(error[1])
    } finally{ setIsSubmiting(false); }
  }
  
  const cancelAction = (e) => {
    e.preventDefault();
    const companyId = pathname.split("/")[2]
    if (data.user.user_type === "user") history.push(`/company/${companyId}`);
    else history.push(`/company/${companyId}/users-list`)
  };

  if (method=== "POST"){
    return (
      <form ref={formRef} onSubmit={onSubmit} style={{ width: '60%' }} className="form-style" formEncType="multipart/form-data">
      <h1>{title}</h1>
      <div className="inputs-container">

        <div className="inputContainer">
          <InputField
            className="customInput"
            name="name"
            label="Nome"
            type="text"
            placeholder="Name"
            required={true}
          />
          <InputField
            className="customInput"
            name="number"
            label="Número de telefone"
            type="tel"
            pattern="[0-9]{2}[0-9]{9}"
            placeholder="Telefone"
            required={true}
            message="Deve ser no formato DDD XXXXX XXXX"
          />
        </div>
        

        <div className="inputContainer">
          <InputField 
            className="customInput"
            name="email" 
            label="E-mail" 
            type="email" 
            placeholder="E-mail" 
            required={true}
          />

          <InputField className="customInput"
            name="alt_email" 
            label="E-mail alternativo" 
            type="email" 
            placeholder="E-mail alternativo" 
          />
        </div>

        <div className="inputContainer">
          <InputField 
            className="customInput"
            name="password" 
            label="Senha" 
            type="password" 
            placeholder="Senha"
            message="No mínimo 6 dígitos, com caractére especial"
            required={true}
          />
          <div className="select-container">
            <label htmlFor="user_type"> Tipo de usuário</label>
            <select 
              className="select"
              style={{ width: '100%' }} 
              name="user_type" 
              type="select" 
              defaultValue="admin"
            >
              <option value="admin">Admin</option>
              <option value="user">Usuário padrão</option>
            </select>
          </div>
        </div>

        <InputField 
          className="customInput"
          label="Empresa" 
          name="company_id" 
          type="text" 
          value={thisCompany?.name}
          disabled={true}
        />
          
        </div>
        <div className="buttons-wrapper">
          <button type="submit" className="form-button" disabled={isSubmiting}>
            Salvar
          </button>
          <button onClick={cancelAction} className="form-button">
            Cancelar
          </button>
        </div>
      </form>
    )
  }else if (method === "PUT"){
    return (
      <form ref={formRef} onSubmit={onSubmit} style={{ width: '60%' }} className="form-style" formEncType="multipart/form-data">
        <h1>{title}</h1>
        <div className="inputs-container">
          <div className="inputContainer">
            <InputField
              className="customInput"
              name="name"
              label="Nome"
              type="text"
              placeholder={userToEdit?.name}
            />
            <InputField
              className="customInput"
              name="number"
              label="Número de telefone"
              type="tel"
              pattern="[0-9]{2}[0-9]{9}"
              placeholder={userToEdit?.number}
              message="Deve ser no formato DDD XXXXX XXXX"
            />
          </div>
          
          <div className="inputContainer">
            <InputField 
              className="customInput"
              name="email" 
              label="E-mail" 
              type="email" 
              placeholder={userToEdit?.email}
            />
            <InputField className="customInput"
              name="alt_email" 
              label="E-mail alternativo" 
              type="email" 
              placeholder={!!userToEdit?.alt_email?userToEdit?.alt_email:"E-mail alternativo"}
            />
          </div>
            <div className="inputContainer">
            <InputField 
              className="customInput"
              name="password" 
              label="Senha" 
              type="password" 
              placeholder="Nova senha"
              message="No mínimo 6 dígitos, com caractére especial"
            />
            <div className="select-container">
              <label htmlFor="user_type"> Tipo de usuário</label>
              {JSON.stringify(userToEdit) !== "{}" 
                ? <select 
                    className="select"
                    style={{ width: '100%' }} 
                    name="user_type" 
                    type="select" 
                    defaultValue={userToEdit?.user_type}
                    disabled={data.user.user_type !== "master"}
                  >
                    <option value="admin">Admin</option>
                    <option value="user">Usuário padrão</option>
                  </select>
            
                : <InputField 
                    className="customInput"
                    name="user_type" 
                    placeholder="Carregando..."
                  />
              }
            </div>
          </div>
  
          <InputField 
            className="customInput"
            label="Empresa" 
            name="company_id" 
            type="text" 
            value={thisCompany?.name ? thisCompany?.name : ""}
            disabled={true}
          />
            
          </div>
          <div className="buttons-wrapper">
            <button type="submit" className="form-button" disabled={isSubmiting}>
              Salvar
            </button>
            <button onClick={cancelAction} className="form-button">
              Cancelar
            </button>
          </div>
        </form>
      )
  }
}
