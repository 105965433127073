import "./styles.css";

export default function HeaderComponent({ title }) {
  return (
    <div className="header">
      <div className="container">
        <div className="title">{title}</div>
      </div>
    </div>
  );
}
