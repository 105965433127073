import { useState, useEffect } from 'react'

import { BallTriangle } from "react-loader-spinner";
import { AiOutlineDoubleRight } from "react-icons/ai";

import { useCompany } from '../../context/companyContext'

import './styles.css'

export default function RPage(){
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const [shouldReset, setShouldReset] = useState(false)
  
  const { rPageUrl } = useCompany()

  useEffect(()=>{
    setShouldReset(true)
    setIsPageLoaded(false)
    const reset = setTimeout(()=>{setShouldReset(false)}, 100)
    return ()=>{
      clearTimeout(reset)
    }
  },[rPageUrl])

  return(
    <div className="rpage-container">
      { !shouldReset &&
      <object  
        onLoad={()=>{ setIsPageLoaded(true) }} 
        className="object" 
        style={{opacity: isPageLoaded ? 1 : 0}} 
        type="text/html" 
        data={rPageUrl} 
        aria-label="Página R"
      />}
      { !isPageLoaded && 
        <div className="spinner">
          <BallTriangle
            type="Puff"
            color="#12e5ec"
            height={150}
            width={150}
          />
          <span className="span">Carregando</span>
          
        </div>
      }
      { isPageLoaded &&
        <button className="button" onClick={()=>{window.open(rPageUrl,'_blank')}}>
          <span>Abrir em nova guia</span>
          <AiOutlineDoubleRight/>
        </button>
      }
    </div>
  )
}