import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useAuth } from '../../../context/authContext'
import { useCompany } from '../../../context/companyContext'
import api from '../../../services/api'
import handleError from '../../../helpers/errorHandler'

import { InputField } from '../../inputField'

import './styles.css'

export default function CompaniesUserForm({ method="POST", title }) {
  const [userToEdit, setUserToEdit] = useState({})
  const [isSubmiting, setIsSubmiting] = useState(false);
  const formRef = useRef();
  const { pathname } = useLocation();
  const history = useHistory();
  const { generateToken, data, signIn } = useAuth();
  const { thisCompany } = useCompany();

  useEffect(()=>{
    const getUser = async () =>{
      try{
        const config = generateToken()
  
        const userId = pathname.split("/").slice(-1)[0];
  
        const response = await api.get(`/company/user/1/${userId}`, config)
  
        setUserToEdit(response.data)
      }catch(err){
        const error = handleError(err)
        alert(error[0])
        if (error.length === 2) console.log(error[1])
      }
    }
    if (method === "PUT") getUser()
  },[pathname, generateToken, method])

  
  const onSubmit = async(e) =>{
    e.preventDefault();
    setIsSubmiting(true);
    
    const userFormToSubmit = [...formRef.current.elements].reduce((total, {name, value})=>{
      // This if is used to handle the field when puting user
      if (method === "PUT" && value === "") return { ...total }
      
      switch(name){
        case "": return { ...total }
        case 'user_type': return { ...total, [name]: value.toLowerCase() };
        case 'company_id': return { ...total, [name]: data.user.company_id }
        default: return { ...total, [name]:value }
      }
    },{})
    
    try{
      const config = generateToken()
      let delay = 0
      if (method === "POST") await api.post("/admin/user", userFormToSubmit, config);
      else if (method === "PUT") {
        const response = await api.put(`/admin/user/${userToEdit.id}`, userFormToSubmit, config);
        if (userToEdit.id === data.user.id){
          const { company_id } = data.user
          const { id, name, user_type } = response.data.user
          const updatedUser = { id, name, company_id, user_type }
          signIn(updatedUser, data.token)
          delay = 200
        }
      }

      setTimeout(()=>{
        if (pathname.split('/')[1] === 'companies')history.push("/companies/users-list")
        else history.push(`/company/${thisCompany.id}/users-list`)
      },delay)
    
    }catch(err){
      const error = handleError(err)
      alert(error[0])
      if (error.length === 2) console.log(error[1])
    }
  }

  const cancelAction = (e) => {
    e.preventDefault();
    
    if (pathname.split('/')[1] === 'companies')history.push("/companies/users-list")
    else history.push(`/company/${thisCompany.id}/users-list`)
    
  };

  if (method=== "POST"){
    return (
      <form ref={formRef} onSubmit={onSubmit} style={{ width: '60%' }} className="form-style" formEncType="multipart/form-data">
      <h1>{title}</h1>
      <div className="inputs-container">

        <div className="inputContainer">
          <InputField
            className="customInput"
            name="name"
            label="Nome"
            type="text"
            placeholder="Name"
            required={true}
          />
          <InputField
            className="customInput"
            name="number"
            label="Número de telefone"
            type="tel"
            pattern="[0-9]{2}[0-9]{9}"
            placeholder="Telefone"
            required={true}
            message="Deve ser no formato DDD XXXXX XXXX"
          />
        </div>
        

        <div className="inputContainer">
          <InputField 
            className="customInput"
            name="email" 
            label="E-mail" 
            type="email" 
            placeholder="E-mail" 
            required={true}
            />

          <InputField className="customInput"
            name="alt_email" 
            label="E-mail alternativo" 
            type="email" 
            placeholder="E-mail alternativo" 
          />
        </div>

          <div className="inputContainer">
          <InputField 
            className="customInput"
            name="password" 
            label="Senha" 
            type="password" 
            placeholder="Senha"
            message="No mínimo 6 dígitos, com caractére especial"
          />
          <InputField 
            className="customInput"
            label="Tipo de usuário" 
            name="user_type" 
            type="text" 
            placeholder="Tipo de usuário" 
            value="Master"
            disabled={true}
            />
        </div>

        <InputField 
          className="customInput"
          label="Empresa" 
          name="company_id" 
          type="text" 
          placeholder="Empresa do usuário" 
          value="Acqua Logic"
          disabled={true}
          />
          
        </div>
        <div className="buttons-wrapper">
          <button type="submit" className="form-button" disabled={isSubmiting}>
            Salvar
          </button>
          <button onClick={cancelAction} className="form-button">
            Cancelar
          </button>
        </div>
      </form>
    )
  }else{
    return (
      <form ref={formRef} onSubmit={onSubmit} style={{ width: '60%' }} className="form-style" formEncType="multipart/form-data">
        <h1>{title}</h1>
        <div className="inputs-container">
  
          <div className="inputContainer">
            <InputField
              className="customInput"
              name="name"
              label="Nome"
              type="text"
              placeholder={userToEdit?.name}
            />
            <InputField
              className="customInput"
              name="number"
              label="Número de telefone"
              type="tel"
              pattern="[0-9]{2}[0-9]{9}"
              placeholder={userToEdit?.number}
              message="Deve ser no formato DDD XXXXX XXXX"
            />
          </div>
          
          <div className="inputContainer">
            <InputField 
              className="customInput"
              name="email" 
              label="E-mail" 
              type="email" 
              placeholder={userToEdit?.email}
          />
  
            <InputField className="customInput"
              name="alt_email" 
              label="E-mail alternativo" 
              type="email" 
              placeholder={!!userToEdit?.alt_email?userToEdit?.alt_email:"E-mail alternativo"}
            />
          </div>
  
            <div className="inputContainer">
            <InputField 
              className="customInput"
              name="password" 
              label="Senha" 
              type="password" 
              placeholder="Nova senha"
              message="No mínimo 6 dígitos, com caractére especial"
            />
            <InputField 
              className="customInput"
              label="Tipo de usuário" 
              name="user_type" 
              type="text" 
              placeholder="Tipo de usuário" 
              value="Master"
              disabled={true}
              />
          </div>
  
          <InputField 
            className="customInput"
            label="Empresa" 
            name="company_id" 
            type="text" 
            placeholder="Empresa do usuário" 
            value="Acqua Logic"
            disabled={true}
            />
            
          </div>
          <div className="buttons-wrapper">
            <button 
              type="submit" 
              className="form-button"
              disabled={isSubmiting}
            > Salvar
            </button>
            <button onClick={cancelAction} className="form-button">
              Cancelar
            </button>
          </div>
      </form>
      )
  }
}
