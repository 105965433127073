import { useState } from 'react'
import { AiFillExclamationCircle, AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
 
import './styles.css'

export function InputField({ 
    children,
    className, 
    label, 
    name, 
    value, 
    onChange, 
    type, 
    placeholder, 
    required=false, 
    disabled=false ,
    pattern=null,
    message=null
  }) {

  const [newType, setNewType] = useState(type)
  const [visible, setVisible] = useState(false)
  
  const toggleView = (state) =>{
    if (visible) setNewType("password")
    else setNewType("text")

    setVisible(state)
  }

  return (
    <div className={className} >
      <label 
        className='form-label' 
        htmlFor={name.toLowerCase()}
        style={{display: 'flex', alignItems: 'center'}}
      >
        {label}

        { type==="password" &&
          (visible 
            ? <AiFillEyeInvisible className={visible ? "eye-focused" : "eye-blur"} onClick={()=>{toggleView(false)}}/>
            : <AiFillEye className={!visible ? "eye-focused" : "eye-blur"} onClick={()=>{toggleView(true)}}/>
          )}

        { !!message &&
          <div className="input-info">
            <AiFillExclamationCircle/>
              <div className="message-container"style={{position: 'relative', display: 'inline-block'}}>
                <p className="message">{message}</p>
              </div>
          </div>
        }
      </label>

      <input 
        style={{ width: '100%' }} 
        className='form-field' 
        id={name.toLowerCase()} 
        name={name} 
        value={value} 
        onChange={onChange} 
        type={newType} 
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        pattern={!!pattern?pattern:null}
      >
        {children}
      </input>
    </div>
  )
}