import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import LoginComponent from "./pages/login";
import Companies from "./pages/companies.jsx";
import Company from "./pages/company.jsx";

import { useAuth } from './context/authContext'  

export default function Routes() {
  const { isLogged, data } = useAuth()
  const { user_type, company_id } = data.user
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/login" />
        </Route>

        <Route path="/login" exact>
          {isLogged()
            ? (user_type === 'master'
            ? <Redirect to="/companies" />
            : <Redirect to="/company" />)
            : <LoginComponent />
          }
        </Route>

        <Route path="/companies">
          {isLogged()
            ? user_type === "master"
            ? <Companies /> 
            : <Redirect to={`/company/${company_id}`} />
            : <Redirect to="/login" />
          }
          
        </Route>

        <Route path="/company">
          {isLogged()
            ? <Company />
            : <Redirect to="/login" />
          }
          
        </Route>

        <Route path="/">
          <Redirect to="/login"/>
        </Route>
    
      </Switch>
    </BrowserRouter>
  );
}
