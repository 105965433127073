import { createContext, useContext, useState, useCallback } from "react";

const CompanyContext = createContext({});

export const CompanyProvider = ({ children }) => {
  const [thisCompany, setThisCompany] = useState(() => {
    const company = localStorage.getItem("@acqua:company");
    if (company) return JSON.parse(company) 
    return {}
  });

  const [menus, setThisMenus] = useState(() => {
    const submenus = localStorage.getItem("@acqua:menus");
    if (submenus) return JSON.parse(submenus) 
    return []
  });

  const [rPageUrl, setRpageUrl] = useState(()=>{
    const url = localStorage.getItem("@acqua:url")
    if (url) return url
    return ""
  })

  const [menuToEdit, setThisMenuToEdit] = useState(()=>{
    const menu = localStorage.getItem("@acqua:menu");
    if (menu) return JSON.parse(menu) 
    return {}
  })

  const [subMenuToEdit, setThisSubMenuToEdit] = useState(()=>{
    const submenu = localStorage.getItem("@acqua:submenu");
    if (submenu) return JSON.parse(submenu) 
    return {}
  })

  const setCompany = useCallback((company)=>{
    setThisCompany(company);
      localStorage.setItem(
        "@acqua:company",
        JSON.stringify(company)
      );
  
  },[])

  const setMenus = useCallback((menus)=>{
    setThisMenus(menus);
    localStorage.setItem(
      "@acqua:menus",
      JSON.stringify(menus)
    );

  },[])

  const setPageUrl = useCallback((url) =>{
    setRpageUrl(url)
    localStorage.setItem("@acqua:url", url)
  },[])

  const setMenuToEdit = useCallback((menu)=>{
    setThisMenuToEdit(menu)
    localStorage.setItem(
      "@acqua:menu",
      JSON.stringify(menu)
    );
  },[])

  const setSubMenuToEdit = useCallback((submenu)=>{
    setThisSubMenuToEdit(submenu)
    localStorage.setItem(
      "@acqua:submenu",
      JSON.stringify(submenu)
    );
    
  },[])

  return (
    <CompanyContext.Provider
      value={{ thisCompany, rPageUrl, menus, menuToEdit, subMenuToEdit, 
        setCompany, setPageUrl, setMenus, setMenuToEdit, setSubMenuToEdit }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => {
  const context = useContext(CompanyContext);
  return context;
};
