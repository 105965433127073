import './styles.css'

export function DeleteBox({ isOpen=false, action, style={} }){
  return(
    <div 
      className="delete-container"
      style={{visibility: isOpen ? 'visible': 'hidden', ...style}}
    >
      <div className="delete-box">
        <span>Deseja realmente excluir?</span>

        <div className="buttons-container">
          <button className="confirm-button" onClick={()=>{action(true)}}>
            Confirmar
          </button>
          <button className="cancel-button" onClick={()=>{action(false)}}>
            Cancelar
          </button>
        </div>
      </div>
      <div className="arrowdown"/>

    </div>
  )
}