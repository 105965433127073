import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../../context/authContext";
import api from "../../../services/api";
import handleError from "../../../helpers/errorHandler";

import { DeleteBox } from "../../deleteBox";
import TablePaginationActions, { theme } from "../../../helpers/tableHelpers";

import { ThemeProvider, Button, ButtonGroup } from "@mui/material";
import {
  Table,
  TableHead,
  TableCell,
  TableFooter,
  TableBody,
  TablePagination,
  TableRow,
} from "@mui/material";

import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlinePlusCircle,
} from "react-icons/ai";

import "./styles.css";

export default function CompaniesUserList() {
  const [isActionBoxOpen, setIsActionBoxOpen] = useState(false);
  const [userToDeleteId, setUserToDeleteId] = useState(null);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(4);
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - users.length)
      : rowsPerPage - users.length;

  const { data, generateToken } = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function loadUsers() {
      try {
        const config = generateToken();

        const response = await api.get("/admin/user", config);
        setUsers(response.data.filter((user)=>user.delete));
      } catch (err) {
        const error = handleError(err);
        alert(error[0]);
        if (error.length === 2) console.log(error[1]);
      }
    }
    loadUsers();
  }, [generateToken]);

  const deleteUser = async (isDeleteConfirmed) => {
    if (!isDeleteConfirmed) {
      setIsActionBoxOpen(false);
      return;
    }

    try {
      const config = generateToken();

      await api.delete(`/admin/user/${userToDeleteId}`, config);

      const filteredCompanies = users.filter(
        (user) => user.id !== userToDeleteId
      );
      setIsActionBoxOpen(false);
      setUsers(filteredCompanies);
    } catch (err) {
      const error = handleError(err);
      alert(error[0]);
      if (error.length === 2) console.log(error[1]);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Table className="companiesUserList" aria-label="Customização">
        <TableHead>
          <TableRow>
          <TableCell              
                className="table-header"
                style={{textAlign: 'start'}} 
                >
                <span style={{paddingLeft: '2rem'}} >
                  Listagem de Usuários Master
                </span>
              </TableCell>
            <TableCell className="hide" />
            <TableCell className="hide" />

            <TableCell className="table-header" align="center">
              <Button
                className="button-add"
                onClick={() => {
                  history.push("/companies/users-list/add");
                }}
              >
                <span className="hide" style={{ marginRight: "0.5rem" }}>
                  Adicionar
                </span>
                <AiOutlinePlusCircle />
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell className="table-head">Nome</TableCell>
            <TableCell className="table-head">Email</TableCell>
            <TableCell className="table-head">Tipo</TableCell>
            <TableCell className="table-head">Opções</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {users
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user) => (
              <TableRow key={user.id} style={{ height: "70px" }}>
                <TableCell className="table-cell">{user.name}</TableCell>
                <TableCell className="table-cell">{user.email}</TableCell>
                <TableCell className="table-cell">{user.user_type}</TableCell>
                <TableCell className="table-cell" align="center">
                  <ButtonGroup
                    style={{ position: "relative" }}
                    aria-label="outlined button group"
                  >
                    <Button
                      className="button-edit"
                      onClick={() => {
                        history.push(`/companies/users-list/edit/${user.id}`);
                      }}
                    >
                      <span className="hide" style={{ marginRight: "0.5rem" }}>
                        Editar
                      </span>
                      <AiOutlineEdit />
                    </Button>
                    {user.delete && user.id !== data.user.id && (
                      <Button
                        className="button-remove"
                        onClick={() => {
                          setIsActionBoxOpen(true);
                          setUserToDeleteId(user.id);
                        }}
                      >
                        <span
                          className="hide"
                          style={{ marginRight: "0.5rem" }}
                        >
                          Excluir
                        </span>
                        <AiOutlineDelete />
                      </Button>
                    )}

                    {user.id !== data.user.id &&
                      user.delete &&
                      userToDeleteId === user.id && (
                        <DeleteBox
                          isOpen={isActionBoxOpen}
                          action={deleteUser}
                        />
                      )}
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 70 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              className="pagination"
              rowsPerPageOptions={[]}
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, nP) => {
                setPage(nP);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </ThemeProvider>
  );
}
