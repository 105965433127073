import React, { useState } from "react";
import { useHistory } from "react-router";

import { useAuth } from "../../../context/authContext";
import { useCompany } from "../../../context/companyContext";
import api from "../../../services/api";
import handleError from '../../../helpers/errorHandler'

import { DeleteBox } from '../../deleteBox'
import TablePaginationActions, { theme } from '../../../helpers/tableHelpers'

import { IconPickerItem } from "react-fa-icon-picker";

import { ThemeProvider, Button, ButtonGroup } from "@mui/material";
import { Table, TableHead, TableCell, TableFooter, TableBody,
  TablePagination, TableRow } from "@mui/material" 

import { AiOutlineEdit, AiOutlineDelete, AiOutlinePlusCircle, AiOutlineUnorderedList, AiOutlineSearch } from 'react-icons/ai' 

import "./styles.css";

export default function CompanyMenusList() {
  const [isActionBoxOpen, setIsActionBoxOpen] = useState(false);
  const [menuToDeleteId, setMenuToDeleteId] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(4);
  
  const { generateToken } = useAuth();
  const { menus, setMenus, thisCompany, setMenuToEdit } = useCompany();
  const history = useHistory();
  
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - menus.length) : rowsPerPage-menus.length;


  const deleteMenu = async (isDeleteConfirmed) => {
    if (!isDeleteConfirmed) {
      setIsActionBoxOpen(false); 
      return
    }

    try {
      const config = generateToken();

      await api.delete(`/admin/link/${menuToDeleteId}`, config);

      const filteredMenus = menus.filter(
        (menu) => menu.id !== menuToDeleteId
      );
      setIsActionBoxOpen(false); 
      setMenus(filteredMenus);
    } catch (err) {
        const error = handleError(err)
        alert(error[0])
        if (error.length === 2) console.log(error[1])
    }
  };
  
  return (
    <ThemeProvider theme={theme}>
        <Table className="companiesMenusList" aria-label="Customização">
          <TableHead>
            <TableRow >
              <TableCell                  
                className="table-header"
                style={{textAlign: 'start'}} 
                >
                <span style={{paddingLeft: '2rem'}} >
                Listagem de Links da Empresa
                </span>
              </TableCell>
              <TableCell></TableCell>
              <TableCell  className="table-header" style={{textAlign: 'end'}}>
                <span style={{paddingRight: '2rem'}} >
                  <Button
                    className="button-add"
                    onClick={() => { history.push(`/company/${thisCompany.id}/menus-list/add`);}}
                  >
                    <span className="hide" style={{marginRight: '0.5rem'}}>Adicionar</span>
                    <AiOutlinePlusCircle/>
                  </Button>
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow  >
              <TableCell  className="table-head">Nome</TableCell>
              <TableCell className="table-head">Ícone</TableCell>
              <TableCell className="table-head" >
                Opções
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {menus
              .slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage)
              .map((menu) => (
                <TableRow   key={menu.id} style={{height: '70px'}}>
                <TableCell 
                  className="table-cell"
                  
                >
                  {menu.name}
                </TableCell>
                <TableCell 
                  className="table-cell"
                >
                  <IconPickerItem icon={menu.icon} color="white" size={20} />
                </TableCell>
                
                
                <TableCell 
                  className="table-cell"
                  align="center"
                >
                  <ButtonGroup style={{position: 'relative'}} aria-label="outlined button group">
                    {!menu.simple 
                      ? <Button
                            className="button-more"
                            onClick={() => {
                              setMenuToEdit(menu)
                              history.push(`/company/${thisCompany.id}/submenus-list`)
                            }}
                          >
                            <span className="hide" style={{marginRight: '0.5rem'}}>Ver Sublinks</span>
                            <AiOutlineUnorderedList/>
                        </Button>
                      : <Button
                          className="button-more"
                          onClick={() => {window.open(menu.rpage_url,'_blank')}}
                          style={{padding: '0 1.5rem'}}
                        >
                          <span className="hide" style={{marginRight: '0.5rem'}}>Ver Página</span>
                          <AiOutlineSearch/>
                        </Button>
                    }
                    <Button
                      className="button-edit"
                      onClick={() => {
                        setMenuToEdit(menu)
                        history.push(`/company/${thisCompany.id}/menus-list/edit`)
                      }}   
                    >
                      <span className="hide" style={{marginRight: '0.5rem'}}>Editar</span>
                      <AiOutlineEdit/>
                    </Button>
                    <Button
                      className="button-remove"
                      onClick={() => {setIsActionBoxOpen(true); setMenuToDeleteId(menu.id)}}
                    >
                      <span className="hide" style={{marginRight: '0.5rem'}}>Excluir</span>
                      <AiOutlineDelete/>
                    </Button>

                    { menuToDeleteId === menu.id && <DeleteBox isOpen={isActionBoxOpen} action={deleteMenu} style={{left: '14.4rem'}}/>}

                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 70 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
            
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                className="pagination"
                rowsPerPageOptions={[]}
                count={menus.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(_, nP)=>{setPage(nP)}}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
    </ThemeProvider>
  );
}
