export default function handleError(error){
  if (!error?.response?.data)  return ["Erro ao realizar operação", error]; 
  
  const response = error.response.data;
  switch(response.message){

    // Erro no banco de dados
    case 'Error accessing database.': return [`Status ${response.status} - Erro ao acessar o banco de dados`, response.data];
    

    // Usuário
    case 'User not found': return [`Status ${response.status} - Erro ao pegar dados do usuário: usuário não encontrado`];
    case 'Error when registering user.':
      switch(response.data){
        case 'Number must have only digits': return [`Status ${response.status} - Erro ao resgistrar usuário: número só deve conter dígitos`]
        default: return [`Status ${response.status} - Erro ao registrar usuário`, response.data];
      }
    case 'Email or number not available': return [`Status ${response.status} - Erro ao atualizar usuário: email ou número não estão disponíveis`]; 
    case 'Error updating user.': return [`Status ${response.status} - Erro ao atualizar usuário: usuário não existe`];
    case 'Error deleting user.': return [`Status ${response.status} - Erro ao deletar usuário: não informou todos os parâmetros`];
    case 'Error deleting user':
      switch(response.data){
        case 'Did not enter all necessary data.': return [`Status ${response.status} - Erro ao deletar usuário: não informou os dados necessários`];
        case 'This user cannot be deleted.': return [`Status ${response.status} - Erro ao deletar usuário: usuário não póde ser deletado`];
        case 'User does not exist': return [`Status ${response.status} - Erro ao deletar usuário: usuário não existe`];
        case 'Did not inform the params id.': return [`Status ${response.status} - Erro ao deletar usuário: não informou o identificador`];
        case 'User must be from your company.': return [`Status ${response.status} - Erro ao deletar usuário: usuário deve ser da sua companhia`];
        case 'The user type must be user or admin, not master': return [`Status ${response.status} - Erro ao deletar usuário: você não pode deletar um usuário master`];
        case 'The user type must be user, not master': return [`Status ${response.status} - Erro ao deletar usuário: você não pode deletar um usuário master`];
        case 'The user type must be user, not admin': return [`Status ${response.status} - Erro ao deletar usuário: voce não pode deletar um usuário admin`];
        default: return [`Status ${response.status} - Erro ao deletar usuário`];
      }
    case 'Credentials are invalid.': return [`Status ${response.status} - Erro ao logar: credenciais são inválidas`];
    case 'User does not exist. check credentials.': return [`Status ${response.status} - Erro ao logar: usuário não existe, cheque as credenciais`];
    case 'Email or Number are already in use': return [`Status ${response.status} - Email ou número já estão em uso`];
    case 'User already exists.': return [`Status ${response.status} - Erro ao realizar operação`, response.data]; 
      

    // Páginas R
    case 'Error get Rpage': return [`Status ${response.status} - Erro ao pegar RPage: o identificador da página não é válido`];
    case 'Error deleting rpage':
      switch(response.data){
        case 'Did not enter all necessary data.': return [`Status ${response.status} - Erro ao deletar páginaR: não inforou todos os dados`];
        default: return [`Status ${response.status} - Erro ao deletar páginaR`, response.data];
      }
    case 'Error deleting rpages':
      switch(response.data){
        case 'Did not enter all necessary data.': return [`Status ${response.status} - Erro ao deletar páginasR: não inforou todos os dados`];
        default: return [`Status ${response.status} - Erro ao deletar páginasR`, response.data];
      }
      

    // Links
    case 'Error registered sublink.': return [`Status ${response.status} - Erro ao cadastrar sublink: link ou companhia não existe`];
    case 'Error updating link.': return [`Status ${response.status} - Erro ao editar link: link não existe`];
    case 'Error deleting link':
      switch(response.data){
        case 'Did not enter all necessary data.': return [`Status ${response.status} - Erro ao deletar link: não informou todos os dados`];
        case 'Link does not exist': return [`Status ${response.status} - Erro ao deletar link: link não existe`];
        default: return [`Status ${response.status} - Erro ao deletar link`];
      }


    // Sublinks
    case 'Error updating sublink.': return [`Status ${response.status} - Erro ao editar sublink: link ou companhia não existe`];
    case 'Error deleting sublink':
      switch(response.data){
        case 'Did not enter all necessary data.': return [`Status ${response.status} - Erro ao deletar sublink: não informou todos os dados`];
        case 'Link does not exist': return [`Status ${response.status} - Erro ao deletar sublink: link não existe`];
        default: return [`Status ${response.status} - Erro ao deletar sublink`];
      }
      
      
    // Comapanhia  
    case 'Company not found': return [`Status ${response.status} - Erro ao carregar dados da companhia: companhia não encontrada`];
    case 'CNPJ is in use': return [`Status ${response.status} - Erro ao cadastrar companhia: CNPJ ja está em uso`];
    case 'company does not exist': return [`Status ${response.status} - Erro ao editar comanhia: companhia não existe`];
    case 'Error deleting company.':
      switch(response.data){
        case 'Did not inform all the necessary data.': return [`Status ${response.status} - Erro ao deletar companhia: não informou os dados necessários`]
        case 'Error deleting company.': return [`Status ${response.status} - Erro ao deletar companhia: companhia não existe`]
        default: return [`Status ${response.status} - Erro ao deletar companhia`]
      }
    
    
    // Casos gerais
    case 'The data entered is not valid':
      switch(response.data){
        case 'You did not enter any data to be updated': return [`Status ${response.status} - Os dados enviados não são válidos: nenhum dado foi enviado`];
        default: return [`Status ${response.status} - Os dados enviados não são válidos`, response.data];
      }


    // Último caso
    default: return [`Status ${response.status} - Erro ao reliazar operação`] 
  }
}
  
  